import 'styles/styles.scss'
import 'swiper/css'
import 'swiper/css/pagination'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export const onRouteUpdate = ({ location }) => {
  setLastPath(location.pathname)
}

const setLastPath = (pathname) => {
  if (!pathname.includes('/account')) {
    sessionStorage.setItem('lastPath', pathname)
  }
}
