import React from 'react'
import Social from 'components/Social'
import { LocalizedLink } from 'gatsby-theme-i18n'
import Logo from 'assets/svg/vi/logo.svg'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ locale }) => {
  const strings = {
    en: {
      email: 'email',
      emailValue: 'zhuoyustudio@zhuoyu.net',
      phone: 'phone',
      phoneValue: '+86 187 2154 4010',
      address: 'address',
      addressValue: `No. 6161 Yuanjiang Road
      Minhang District, Shanghai, China 201111`,
      copyright: `© Copyright Zhuoyu. All rights reserved. <span><a href="https://lantern.digital" target="_blank">Website design</a></span>`,
    },
    zh: {
      email: '邮箱',
      emailValue: 'zhuoyustudio@zhuoyu.net',
      phone: '电话',
      phoneValue: '+86 187 2154 4010',
      address: '地址',
      addressValue: `上海市闵行区元江路6161号
      邮编201111`,
      copyright: `©版权所有 上海拙鱼文化传播有限公司 <span><a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a></span>`,
    },
  }
  const tr = strings[locale]

  return (
    <div id='footer' className={`${styles.footer} bg-dark`}>
      <Inview className={`${styles.inner} fade-in up`}>
        <div className={styles.wrap}>
          <div className={`${styles.columnLogo} `}>
            <LocalizedLink to='/'>
              <Logo />
            </LocalizedLink>
          </div>
          <div className={`${styles.columnOther}`}>
            <div className={styles.main}>
              <div className={`${styles.contact} `}>
                <div className={styles.table}>
                  <div className={styles.row}>
                    <h6>{tr.email}</h6>
                    <p className='smallest'>
                      <a href={`mailto:${tr.emailValue}`}>{tr.emailValue}</a>
                    </p>
                  </div>
                  <div className={styles.row}>
                    <h6>{tr.phone}</h6>
                    <p className='smallest'>{tr.phoneValue}</p>
                  </div>
                  <div className={styles.row}>
                    <h6>{tr.address}</h6>
                    <p className='smallest'>{tr.addressValue}</p>
                  </div>
                </div>
              </div>
              <div className={styles.socialWrap}>
                <Social isWhite={true} isFooter={true} isLeftMobile={true} />
              </div>
            </div>
            <div className={styles.credit}>
              <p
                className='smallest-2'
                dangerouslySetInnerHTML={{ __html: tr.copyright }}
              />
            </div>
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Component
