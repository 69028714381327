import React from 'react'
import LinkWrap from 'components/LinkWrap'
import LogoStandardSvg from 'assets/svg/vi/logo.svg'
import LogoInverseSvg from 'assets/svg/vi/logo-inverse.svg'
import LogoGoldSvg from 'assets/svg/vi/logo-gold.svg'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps }) => {
  const {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderUp,
    isHeaderHidden,
    isHeaderBgVersion,
  } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  const headerBgVersionClass = isHeaderBgVersion ? styles.headerBgVersion : ''
  const headerHiddenClass = isHeaderHidden ? styles.headerHidden : ''
  return (
    <div
      id='header'
      className={`${styles.header} ${headerUpClass}  ${menuOpenClass} ${headerBgVersionClass}`}
    >
      <div className={`${styles.headerHiddenWrap}  ${headerHiddenClass}`}>
        <Inview className={`${styles.inner} fade-in`}>
          <LinkWrap to='/' className={styles.logo}>
            <div className={styles.standard}>
              <LogoStandardSvg />
            </div>
            <div className={styles.inverse}>
              <LogoInverseSvg />
            </div>
            <div className={styles.gold}>
              <LogoGoldSvg />
            </div>
          </LinkWrap>
          <div
            className={`${styles.thumb} ${menuOpenClass} fade-in`}
            onClick={toggleIsMenuOpen}
          >
            <span />
            <span />
            <span />
          </div>
        </Inview>
      </div>
    </div>
  )
}

export default Component
