// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-types-collection-index-js": () => import("./../../../src/contentTypes/collection/index.js" /* webpackChunkName: "component---src-content-types-collection-index-js" */),
  "component---src-content-types-designer-index-js": () => import("./../../../src/contentTypes/designer/index.js" /* webpackChunkName: "component---src-content-types-designer-index-js" */),
  "component---src-content-types-page-index-js": () => import("./../../../src/contentTypes/page/index.js" /* webpackChunkName: "component---src-content-types-page-index-js" */),
  "component---src-content-types-product-index-js": () => import("./../../../src/contentTypes/product/index.js" /* webpackChunkName: "component---src-content-types-product-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-change-password-js": () => import("./../../../src/pages/account/change-password.js" /* webpackChunkName: "component---src-pages-account-change-password-js" */),
  "component---src-pages-account-create-account-js": () => import("./../../../src/pages/account/create-account.js" /* webpackChunkName: "component---src-pages-account-create-account-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-request-new-password-js": () => import("./../../../src/pages/account/request-new-password.js" /* webpackChunkName: "component---src-pages-account-request-new-password-js" */),
  "component---src-pages-account-welcome-js": () => import("./../../../src/pages/account/welcome.js" /* webpackChunkName: "component---src-pages-account-welcome-js" */)
}

