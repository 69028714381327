import React, { useState, useEffect, useRef } from 'react'
import { globalHistory } from '@reach/router'
import Header from 'components/Header'
import Menu from 'components/Menu'
import Footer from 'components/Footer'
import SessionFooter from 'components/SessionFooter'
import * as styles from './styles.module.scss'
import { LocaleContext } from 'gatsby-theme-i18n'

const Layout = ({ children, location }) => {
  const locale = React.useContext(LocaleContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHeaderUp, setIsHeaderUp] = useState(false)
  const [isHeaderHidden, setIsHeaderHidden] = useState(false)
  const [isHeaderBgVersion, setIsHeaderBgVersion] = useState(false)
  const [isHomeBannerStarted, setIsHomeBannerStarted] = useState(false)
  const [isHomeBannerOpen, setIsHomeBannerOpen] = useState()

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    setIsHeaderBgVersion(false)
    // console.log('set bg false: from layout useEffect first time')

    const homePathnames = ['/', '/en', '/en/']
    if (!homePathnames.includes(location.pathname)) {
      // only do home intro if starting from home
      // avoids issue: start at about, go to home for first time, click back before opening cover = stuck
      setIsHomeBannerStarted(true)
      setIsHomeBannerOpen(true)
    }

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'POP') {
        setTimeout(() => {
          setIsMenuOpen(false)
          setIsHeaderBgVersion(false)
          // console.log('set bg false: from layout useEffect push/pop')
        }, 100)
      }
    })
  }, [setIsMenuOpen])

  const layoutProps = {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderUp,
    isHeaderHidden,
    setIsHeaderHidden,
    isHeaderBgVersion,
    setIsHeaderBgVersion,
    isHomeBannerStarted,
    setIsHomeBannerStarted,
    isHomeBannerOpen,
    setIsHomeBannerOpen,
  }

  let lockedClass = ''
  if (isHomeBannerOpen === false) lockedClass = styles.locked

  return (
    <div className={`${styles.component} ${lockedClass}`}>
      <SessionFooter />
      <Header layoutProps={layoutProps} />
      <Menu layoutProps={layoutProps} location={location} />
      <div className={styles.pageWrap}>
        <div>{React.cloneElement(children, { layoutProps })}</div>
        {!location.pathname.includes('/contact') && <Footer locale={locale} />}
      </div>
    </div>
  )
}

export default Layout
