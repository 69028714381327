// extracted by mini-css-extract-plugin
export var header = "styles-module--header--1nv0f";
export var headerUp = "styles-module--header-up--3drR6";
export var menuOpen = "styles-module--menu-open--2Bfzj";
export var logo = "styles-module--logo--3et5j";
export var standard = "styles-module--standard--1CSIJ";
export var inverse = "styles-module--inverse--1Na8p";
export var gold = "styles-module--gold--20n8u";
export var headerHiddenWrap = "styles-module--header-hidden-wrap--2mDUP";
export var headerHidden = "styles-module--header-hidden--1viBE";
export var inner = "styles-module--inner--9A5J7";
export var thumb = "styles-module--thumb--2lRTG";
export var headerBgVersion = "styles-module--header-bg-version--1vsqX";