import React from 'react'
import IconWechat from 'assets/svg/social/wechat.svg'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const Component = ({
  version = 'light',
  isLeft = false,
  isLeftMobile = false,
}) => {
  const versionClass =
    version === 'dark' ? styles.versionDark : styles.versionLight
  const isLeftClass = isLeft ? styles.isLeft : ''
  const isLeftMobileClass = isLeftMobile ? styles.isLeftMobile : ''
  return (
    <div className={`${versionClass} ${isLeftClass} ${isLeftMobileClass}`}>
      <div className={styles.items}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <IconWechat />
          </div>
          <div className={styles.qr}>
            <StaticImage
              src='../../assets/images/qr-wechat-circle.jpg'
              alt=''
            />
          </div>
        </div>
        <div className={styles.item}>
          <a
            href='https://www.xiaohongshu.com/vendor/624538fd1f46830001739f32?naviHidden=yes&xhsshare=CopyLink&appuid=5ca2bf63000000001100fadf'
            target='_blank'
            className={styles.icon}
          >
            {version === 'light' && (
              <StaticImage
                src='../../assets/svg/social/xiaohongshu-white.png'
                alt=''
              />
            )}
            {version === 'dark' && (
              <StaticImage
                src='../../assets/svg/social/xiaohongshu-green.png'
                alt=''
              />
            )}
          </a>
        </div>
        <div className={styles.item}>
          <a
            href='https://zhuoyucj.tmall.com/shop/view_shop.htm?spm=a230r.1.14.4.682f16f5TyIAIA&user_number_id=2214782176150'
            target='_blank'
            className={styles.icon}
          >
            {version === 'light' && (
              <StaticImage
                src='../../assets/svg/social/tmall-white.png'
                alt=''
              />
            )}
            {version === 'dark' && (
              <StaticImage
                src='../../assets/svg/social/tmall-green.png'
                alt=''
              />
            )}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Component
