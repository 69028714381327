import React, { useState, useEffect } from 'react'
import * as styles from './styles.module.scss'
import { globalHistory } from '@reach/router'

const Component = () => {
  // just for testing
  const isShowing = false

  const [lastPath, setLastPath] = useState(null)
  const [user, setUser] = useState(null)

  const updateVars = () => {
    // not reliable
    setTimeout(() => {
      setLastPath(sessionStorage.getItem('lastPath'))
      setUser(sessionStorage.getItem('user'))
    }, 1)
  }

  useEffect(() => {
    updateVars()
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        updateVars()
      }
    })
  }, [])

  const showClass = isShowing ? styles.show : ''
  return (
    <div className={`${styles.component} ${showClass}`}>
      <ul>
        <li>lastPath: {lastPath}</li>
        <li>user: {user}</li>
      </ul>
    </div>
  )
}

export default Component
