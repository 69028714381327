import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Arrow from 'components/Arrow'
import EmailSvg from 'assets/svg/icons/email.svg'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const Component = ({ content, className = '', onClick = null }) => {
  const { to, text, linkType, isDisabled, bypassLocalization } = content || {}
  const addClass = styles[className]
  const disabledClass = isDisabled ? styles.disabled : ''

  if (to && linkType === 'email') {
    return (
      <a
        href={`mailto:${to}`}
        className={`${styles.button} ${styles.email} ${addClass} ${disabledClass}`}
        onClick={onClick}
      >
        <EmailSvg />
        <Inner text={text} isEmail={true} />
      </a>
    )
  } else if (linkType === 'qr') {
    return (
      <a
        className={`${styles.button} ${styles.qrWrap} ${addClass} ${disabledClass}`}
      >
        <div className={styles.qr}>
          <StaticImage src='../../assets/images/qr-wechat-bigger.jpg' alt='' />
        </div>
        <Inner text={text} />
      </a>
    )
  } else if (to && linkType === 'external') {
    return (
      <a
        href={to}
        target='_blank'
        className={`${styles.button} ${addClass} ${disabledClass}`}
        onClick={onClick}
      >
        <Inner text={text} />
      </a>
    )
  } else if (linkType === 'submit') {
    return (
      <button
        className={`${styles.button} ${addClass} ${disabledClass}`}
        type='submit'
        disabled={isDisabled}
        onClick={onClick}
      >
        <Inner text={text} />
      </button>
    )
  } else if (to) {
    return (
      <LinkWrap
        to={to}
        bypassLocalization={bypassLocalization}
        className={`${styles.button} ${addClass} ${disabledClass}`}
        onClick={onClick}
      >
        <Inner text={text} />
      </LinkWrap>
    )
  } else {
    return (
      <div
        className={`${styles.button} ${className} ${disabledClass}`}
        onClick={onClick}
      >
        <Inner text={text} />
      </div>
    )
  }
}
export default Component

const Inner = ({ text, direction = 'right', isEmail }) => {
  return (
    <>
      <span className={styles.root}>{text}</span>
      <span className={styles.mask}>
        <span className={styles.hover}>
          {isEmail && <EmailSvg />}
          {text}
          <span className={styles.arrowWrap}>
            <Arrow />
          </span>
        </span>
      </span>
    </>
  )
}
