import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import LineLink from 'components/LineLink'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'

const Component = ({ data, location, layoutProps }) => {
  const locale = React.useContext(LocaleContext)
  const { isMenuOpen, toggleIsMenuOpen } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''

  let menu = data.menus.nodes[0].frontmatter[locale]

  let pages = data.pages.nodes
  pages = pages.map((page) => page.frontmatter[locale])
  menu.main.forEach((item) => {
    item.data = pages.find((page) => page.slug === item.page)
    item.submenu?.forEach((subitem) => {
      subitem.data = pages.find((page) => page.slug === subitem.page)
    })
  })
  const lastItemStagger = (menu.main.length + 1) * 50 + 100

  return (
    <>
      <div
        className={`${styles.overlay} ${menuOpenClass}`}
        onClick={toggleIsMenuOpen}
      />
      <Inview className={`${styles.menu} ${menuOpenClass}`}>
        <div className={styles.inner}>
          <div className={styles.upper}>
            <div>
              <div>
                {menu.main?.map((item, i) => (
                  <M0 content={item} location={location} key={i} i={i} />
                ))}
                <AccountLink i={menu.main.length + 1} locale={locale} />
              </div>
              <div
                className={`${styles.social} fade-in up stagger-${lastItemStagger}`}
              >
                {/* <Social /> */}
              </div>
            </div>
          </div>
          <div
            className={`${styles.lanWrap} fade-in up stagger-${lastItemStagger}`}
          >
            <Lan />
          </div>
        </div>
      </Inview>
    </>
  )
}

const Lan = () => {
  const locale = React.useContext(LocaleContext)
  return (
    <div className={styles.lan}>
      <div className={styles.inner}>
        {locale === 'zh' && (
          <ButtonLink
            content={{
              text: 'EN',
              to: '/en',
              linkType: 'page',
              bypassLocalization: true,
            }}
            className='darker'
          />
        )}
        {locale === 'en' && (
          <ButtonLink
            content={{
              text: '中文',
              to: '/',
              linkType: 'page',
              bypassLocalization: true,
            }}
            className='darker'
          />
        )}
      </div>
    </div>
  )
}
const M0 = ({ content, location, i }) => {
  const { submenu, altTitle, data } = content
  const { title, path } = data
  const setTitle = altTitle ? altTitle : title

  let forceActive = false
  if (path === '/furniture') {
    if (
      location.pathname.includes('/furniture') ||
      location.pathname.includes('/teaware')
    ) {
      forceActive = true
    }
  }

  return (
    <div className={`${styles.m0} fade-in up stagger-${i * 50 + 100}`}>
      <h3>
        <LineLink
          content={{ text: setTitle, to: path, linkType: 'page' }}
          customStyle='m0'
          partiallyActive={path !== '/'}
          forceActive={forceActive}
        />
      </h3>
      {submenu && (
        <div className={styles.submenu}>
          {submenu.map((item, i) => (
            <M1 content={item} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

const AccountLink = ({ i, locale }) => {
  const logOut = () => {
    sessionStorage.removeItem('user')
    const setTo = locale === 'en' ? '/en' : '/'
    // if (location.pathname !== setTo) {
    //   navigate(setTo)
    // }
    window.location.pathname = setTo
  }

  const strings = {
    en: {
      login: 'Login',
      logOut: 'Log Out',
    },
    zh: {
      login: '登陆',
      logOut: '登出',
    },
  }
  const tr = strings[locale]

  return (
    <div className={`${styles.m0} fade-in up stagger-${i * 50 + 100}`}>
      <h3>
        {typeof sessionStorage !== 'undefined' &&
          !sessionStorage.getItem('user') && (
            <LineLink
              content={{ text: tr.login, to: '/account', linkType: '' }}
              customStyle='m0'
              partiallyActive={true}
            />
          )}
        {typeof sessionStorage !== 'undefined' &&
          sessionStorage.getItem('user') && (
            <div onClick={() => logOut()}>
              <LineLink
                content={{ text: tr.logOut, linkType: '' }}
                customStyle='m0'
              />
            </div>
          )}
      </h3>
    </div>
  )
}

const M1 = ({ content }) => {
  const { data } = content
  const { title, path } = data
  return (
    <div className={styles.m1}>
      <p>
        <LineLink
          content={{ text: title, to: path, linkType: 'page' }}
          customStyle='m1'
          partiallyActive={path !== '/'}
        />
      </p>
    </div>
  )
}

export default function Data({ location, layoutProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "primary" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
                zh {
                  title
                  main {
                    page
                    altTitle
                    submenu {
                      page
                    }
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Component data={data} location={location} layoutProps={layoutProps} />
      )}
    />
  )
}

// submenu {
//   page
// }
