import React from 'react'
import LinkWrap from 'components/LinkWrap'
import GlobeSvg from 'assets/svg/icons/globe.svg'
import * as styles from './styles.module.scss'

const Component = ({
  content,
  onClick = null,
  customStyle = '',
  addGlobeIcon = false,
  partiallyActive,
  forceActive = false,
}) => {
  const { to, text, linkType } = content || {}

  if (to && linkType === 'email') {
    return (
      <a
        href={`mailto:${to}`}
        className={`${styles.component} ${styles[customStyle]}`}
        onClick={onClick}
      >
        <span>{text}</span>
      </a>
    )
  } else if (to && linkType === 'external') {
    return (
      <a
        href={to}
        target='_blank'
        className={`${styles.component} ${styles[customStyle]}`}
        onClick={onClick}
      >
        {addGlobeIcon && <GlobeSvg />}
        <span>{text}</span>
      </a>
    )
  } else if (to) {
    const forceActiveClass = forceActive ? styles.active : ''
    return (
      <LinkWrap
        to={to}
        className={`${styles.component} ${styles[customStyle]} ${forceActiveClass}`}
        activeClassName={styles.active}
        partiallyActive={partiallyActive}
        onClick={onClick}
      >
        <span>{text}</span>
      </LinkWrap>
    )
  } else {
    return (
      <a
        className={`${styles.component} ${styles[customStyle]}`}
        onClick={onClick}
      >
        <span>{text}</span>
      </a>
    )
  }
}

export default Component
