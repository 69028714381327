// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--22w0l";
export var inner = "styles-module--inner--lJD3a";
export var wrap = "styles-module--wrap--37yWY";
export var columnLogo = "styles-module--column-logo--2_LvK";
export var columnOther = "styles-module--column-other--1ZgNB";
export var main = "styles-module--main--2Fcf2";
export var contact = "styles-module--contact--3Wsm2";
export var table = "styles-module--table--3mU7c";
export var row = "styles-module--row--gKlwi";
export var credit = "styles-module--credit--2Kyq-";
export var socialWrap = "styles-module--social-wrap--Aru0c";