// extracted by mini-css-extract-plugin
export var button = "styles-module--button--1p15Z";
export var root = "styles-module--root--1zK9A";
export var mask = "styles-module--mask--t0qAp";
export var hover = "styles-module--hover--UMkdJ";
export var arrowWrap = "styles-module--arrow-wrap--1K2ec";
export var on = "styles-module--on--heVfc";
export var disabled = "styles-module--disabled--3vm7W";
export var email = "styles-module--email--3AciC";
export var qrWrap = "styles-module--qr-wrap--F_FNj";
export var qr = "styles-module--qr--2Iqg_";
export var darker = "styles-module--darker--143kL";